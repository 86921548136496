<template>
  <div class="container">
    <div class="pageTitle">
      <div @click="backs"><img src="@/assets/back.png" alt="" srcset="" /></div>
      优惠券
    </div>
    <div class="box">
      <div
        class="coupon"
        :style="{
          borderColor: item.checked ? '#EF0923' : 'rgba(239, 9, 35, 0.1000)',
        }"
        v-for="(item, index) in noUseRecord"
        :key="index"
        @click="choose(item, index)"
      >
        <div class="infobox">
          <div class="name">
            <div class="type">{{ item.coupon_title }}</div>
            <div class="time">有效期至{{ format(item.end_use_time) }}</div>
          </div>
          <div class="boxnum">
            <div class="num">
              <template v-if="$route.query.type == 0">¥</template>
              <span v-if="$route.query.type == 0">{{
                formatNum(item.coupon_price)
              }}</span>
              <span v-else>{{ item.coupon_title }}</span>
              <van-checkbox
                v-model="item.checked"
                checked-color="#ee0a24"
              ></van-checkbox>
            </div>
          </div>
        </div>
        <div class="tips">不可与会员折扣同时使用</div>
      </div>
    </div>

    <div class="btn1" @click="sure">确定</div>
  </div>
</template>

<script>
import { getNoUseRecord } from "../utils/api";
export default {
  components: {},
  props: {},
  data() {
    return {
      type: this.$route.query.type ? this.$route.query.type : 0, // 0  折扣   1优惠
      checked: true,
      noUseRecord: [],
      chooseItem: {},
      indx: 0,
      zkid: this.$route.query.zkid,
      yhid: this.$route.query.yhid,
    };
  },
  watch: {},
  computed: {},
  methods: {
    backs() {
      let path = this.$route.query;
      this.$router.push({
        path: "/crmPay",
        query: {
          ...this.$route.query,
          // userPhone: path.userPhone,
          // crm_weukql: path.crm_weukql,
          // tname: path.tname,
          // price: path.price,
          // pname: path.pname,
          // openid: path.openid,
          // cindex: 0, // 3  折扣
          // dindex: 0, //0优惠
        },
      });
    },
    formatNum(val) {
      return val.split(".")[0];
    },
    format(val) {
      return val.split(" ")[0];
    },
    choose(items, index) {
      // debugger;
      let arr = this.noUseRecord;
      arr.map((item, indexs) => {
        item.checked = index == indexs ? !item.checked : false;
      });
      this.chooseItem = items;
      this.indx = index;
      this.noUseRecord = arr;
    },
    sure() {
      // type 0优惠券   3 折扣
      let isRecord = 1; //取消优惠券
      let iszk = 1; //取消折扣
      let arr = this.noUseRecord;
      var arrs = [];
      arr.map((item, indexs) => {
        if (item.checked) {
          arrs.push(item);
        }
      });
      if (this.$route.query.type == 3) {
        if (arrs.length > 0) {
          iszk = 1;
          isRecord = this.$route.query.isRecord;
        } else {
          iszk = 0;
          isRecord = this.$route.query.isRecord;
        }
      } else {
        if (arrs.length > 0) {
          isRecord = 1;
          iszk = this.$route.query.iszk;
        } else {
          iszk = this.$route.query.iszk;
          isRecord = 0;
        }
      }
      // return;
      let path = this.$route.query;
      console.log(path, "888888888889999");
      this.$router.push({
        path: "/crmPay",
        query: {
          ...this.$route.query,
          isRecord: isRecord,
          iszk: iszk,
          // type:
          // ...this.chooseItem,
          yhid: this.yhidfn(isRecord),
          zkid: this.zkidfn(iszk),
        },
      });
    },
    // 判断选择了优惠券  没选0
    yhidfn(val) {
      var path = this.$route.query;
      let num = null;
      if (val == 0) {
        num = 0;
      } else {
        num =
          path.type == 0
            ? this.chooseItem.id
              ? this.chooseItem.id
              : 0
            : path.yhid;
      }
      return num;
    },
    // 是否选择了 折扣券   没选 0
    zkidfn(val) {
      var path = this.$route.query;
      let num = null;
      if (val == 0) {
        num = 0;
      } else {
        num =
          path.type == 3
            ? this.chooseItem.id
              ? this.chooseItem.id
              : 0
            : path.zkid;
      }
      return num;
    },
    getNoUseRecordfn() {
      // type 0优惠券
      getNoUseRecord({
        phone: this.$route.query.userPhone,
        type: this.$route.query.type,
        is_use_coupon: this.$route.query.is_use_coupon,
        product_id: this.$route.query.product_id,
      }).then((res) => {
        var q = this.$route.query;
        let arr = res.data;
        // cindex: 0, // 3  折扣
        // dindex: 0, //0优惠
        // isRecord  //0 取消优惠券 1使用
        //iszk//0 取消折扣 1使用
        arr.map((item, index) => {
          item.checked = false;
          return item;
        });
        if (this.$route.query.type == 3) {
          if (q.iszk == 1) {
            // arr[this.$route.query.cindex].checked = true;
            arr.map((item, index) => {
              item.checked = false;
              if (item.id == this.zkid) {
                item.checked = true;
              }
              return item;
            });
          }
        } else {
          if (q.isRecord == 1) {
            arr.map((item, index) => {
              item.checked = false;
              if (item.id == this.yhid) {
                item.checked = true;
              }
              return item;
            });
          }
        }

        this.noUseRecord = arr;
        // this.chooseItem = arr[0];
      });
    },
  },
  created() {
    this.getNoUseRecordfn();
  },
  mounted() {},
};
</script>
<style lang="scss" scoped>
.container {
  .pageTitle {
    display: flex;
    align-items: center;
    font-size: 18px;
    font-family: PingFangSC-Regular, PingFang SC;
    // font-weight: 400;
    color: #333333;
    justify-content: center;
    position: relative;
    width: 100%;
    height: 44px;
    div {
      position: absolute;
      left: 16px;
      // background: red;
      display: flex;
      align-items: center;
      padding-right: 10px;
      // background: red;
    }
    // background: #fff;
    img {
      width: 12px;
      height: 44px;
    }
  }
  .coupon {
    width: 343px;
    height: 144px;
    background: rgba(239, 9, 35, 0.02);
    border-radius: 8px;
    border: 1px solid rgba(239, 9, 35, 0.1);
    margin: 16px auto;
    .infobox {
      display: flex;
      justify-content: space-between;
      height: 60px;
      margin: 20px 20px 0px 20px;
      border-bottom: 1px dashed rgba(255, 202, 210, 1);
    }
    .boxnum {
      // display: flex;

      // align-items: center;
      .num {
        font-size: 14px;
        font-family: PingFangSC-Medium, PingFang SC;
        // font-weight: 500;
        color: #ef0923;
        display: flex;
        // height: 37px;
        line-height: 1;
        align-items: flex-end;
        margin-top: 7px;
        span {
          font-size: 26px;
          margin-left: 2px;
          margin-right: 24px;
        }
      }
    }
    .type {
      font-size: 16px;
      font-family: PingFangSC-Medium, PingFang SC;
      // font-weight: 500;
      color: #333333;
    }
    .time {
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      // font-weight: 400;
      color: #999999;
      margin-top: 8px;
    }
  }
  .tips {
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    // font-weight: 400;
    color: #999999;
    margin-top: 18px;
    margin-left: 20px;
  }
  .btn1 {
    position: fixed;
    bottom: 0px;
    width: 343px;
    margin: 16px 16px 12px;
    height: 44px;
    background: #ef0923;
    border-radius: 22px;
    font-size: 16px;
    font-family: PingFangSC-Medium, PingFang SC;
    // font-weight: 500;
    color: #ffffff;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .box {
    margin-bottom: 80px;
  }
}
</style>
